<template>
    <div class="shop_foods">
        <el-select v-model="selectedA" placeholder="请选择分类" @change="changecat1(selectedA)">
            <el-option v-for="item in list" :key="item.id" :label="item.cat_name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="selectB" multiple placeholder="请选择菜品" collapse-tags @change="changecat2($event, )">
            <el-option v-for="child in childList" :key="child.prod_id" :checked="child.checked" :label="child.prod_name" :value="child.prod_id"></el-option>
        </el-select>
    </div>
</template>
<script>
import {getShopFoodsList} from '../../utils/get_public_data.js'

export default {
    props: {
        ent_id: {type: String | Number, default: ()=>''},
        shop_id: {type: String | Number, default: ()=>''},
        allSelected: {type: String, default: ()=>''}
    },
    data() {
        return {
            list: [],
            childList: [],
            selectedA: '',
            selectB: []
        }
    },
    mounted() {
        this.getFoodsList()
    },
    methods: {
        async getFoodsList() {
            this.list = await getShopFoodsList(this.ent_id, this.shop_id)
            this.initSelected()
        },
        // 回显数据处理
        initSelected() {
            console.log('回显-------')
            if(this.allSelected) {
                let arr = this.allSelected.split(',')
                this.list.map(item=>{
                    item.prod_children.map(child=>{
                        if(child.prod_id == arr[0]){
                            this.changecat1(item.id)
                        }
                    })
                })
                arr.map(item=>{
                    this.selectB.push(item)
                })
                
            }
        },
        changecat1(id) {
            this.selectedA = id
            this.list.map(item=>{
                if(item.id == id) {
                    this.childList = item.prod_children
                }
            })
        },
        changecat2(arr) {
            let selectAllList = []
            this.list.map(item=>{
                item.prod_children.map(child=>{
                    arr.map(checked=>{
                        if(checked==child.prod_id) {
                            selectAllList.push(child)
                        }
                    })
                })
            })
            this.$emit('getSelectedFoods', selectAllList, arr)
        },
        
    }
}
</script>