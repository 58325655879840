<!-- // earl 2021-09-06 -->
<template>
  <div class="business-desc-box">
    <div class="EditorialEnterprise_abc">
      <div class="EditorialEnterprise-title"><span></span>编辑企业门店信息</div>
      <el-alert title="视频支持格式：mp4,mov,m4v,3gp,avi,m3u8,webm" type="warning" style="margin-bottom: 15px;"></el-alert>
      <div class="EditorialEnterprise-Information">
        <el-form ref="form" :model="submitForm" label-width="110px">
          <el-form-item label="场景设置：" v-for="(item, index) in submitForm.list" :key="index">
            <p><el-input v-model="item.scene" placeholder="请输入描述" style="width:250px"></el-input> <i class="el-icon-delete" @click="deleteScene(item, index)"></i></p>
            <!-- <div class="tab_upload"><span :class="{'active': item.uploadType==1}" @click="item.uploadType=1">上传图片</span><span :class="{'active': item.uploadType==2}" @click="item.uploadType=2">上传视频</span></div> -->
            <p class="shangchuan">
              <ul class="img_list_upload">
                <!-- 图片 -->
                <!-- <template v-if="item.uploadType==1 && (item.img_url &&  item.img_url.url)">
                  <li v-for="(child, indx) in [item.img_url]" :key="indx">
                    <img :src="child.url" />
                    <span class="opt_thum"><i class="el-icon-delete" @click="deleteImgBtn(item, index)"></i></span>
                  </li>
                </template> -->
                <!-- 视频 -->
                <!-- <template v-if="item.uploadType==2 && (item.img_url &&  item.img_url.url)">
                  <li class="video_style" v-for="(child, indx) in [item.img_url]" :key="indx">
                    <video :src="child.url" controls="controls">您的浏览器不支持video标签，请更换或升级浏览器。</video>
                    <span class="opt_thum"><i class="el-icon-delete" @click="deleteImgBtn(item, index)"></i></span>
                  </li>
                </template>
                <li v-if="!item.img_url || !item.img_url.url" class="edit_upload_img" @click="uploadImgBtn(index)">点击上传</li> -->
                <!-- <li v-if="item.img_url &&  item.img_url.url" :class="{'video_bg': item.uploadType == '2'}">
                  
                  <span class="opt_thum"><i class="el-icon-delete" @click="deleteImgBtn(item, index)"></i></span>
                </li> -->
                <li v-if="item.img_url &&  item.img_url.url" :class="{'video_bg': item.uploadType == '2'}">
                  <div class="file_url_show" v-if="item.img_url.url">
                    <img :src="item.img_url.url" v-if="item.uploadType == '1'" />
                    <video :src="item.img_url.url" v-if="item.uploadType == '2'" controls="controls">您的浏览器不支持video标签，请更换或升级浏览器。</video>
                  </div>
                  <p class="del_btn" @click="delImg(item)">删除{{item.uploadType == '1'? '图片': '视频'}}</p>
                </li>
                
                <li v-if="!item.img_url || !item.img_url.url" style="border: none; height: auto;">
                  <uploadSingle @uploadSuccess="uploadSuccess($event, item, index)" />
                </li>
                <!-- <input v-if="!item.img_url || !item.img_url.url" class="edit_upload_img_input" type="file" @change="earlGetImgBasea($event, item, index)"> -->
              </ul>
            </p>
            <el-button v-if="index==submitForm.list.length-1" @click="addScene">+ 新增</el-button>
          </el-form-item>
          <el-form-item label="是否展示评价：">
            <el-radio-group v-model="submitForm.flag_show_review">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="特色菜展示："> -->
            <!-- <shopFoodsNew :ent_id="ent_id" :shop_id="shop_id" :allSelected="submitForm.special_prod" @getSelectedFoods="getSelectedFoods"></shopFoodsNew> -->
            <!-- <shop-foods ref="shopFoods" :ent_id="ent_id" :shop_id="shop_id" :allSelected="submitForm.special_prod" @getSelectedFoods="getSelectedFoods"></shop-foods> -->
          <!-- </el-form-item> -->
          <el-form-item label="">
            <div class="Button-box">
              <el-button @click="onSubmit">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
        
      </div>
    </div>
    <!-- <upload-image v-if="uploadType==1" :uploadMultipleDia.sync="uploadMultipleDia" :sizeLimit="1 * 1024" @afterUpload="afterUploadImg"></upload-image>
    <upload-video v-if="uploadType==2" :uploadMultipleDia.sync="uploadMultipleDia" :sizeLimit="1 * 1024" @afterUpload="afterUploadImg"></upload-video> -->
  </div>
</template>

<script>
  // import uploadImage from "../../uploadFile/image"
  // import uploadVideo from "../../uploadFile/video"
  import uploadSingle from '../../uploadFile/uploadSingle'
  import {insert_shop_intro, query_shop_intro} from '../../../api/store'
  import shopFoods from '../../foodsList/shopFoods.vue'
  import shopFoodsNew from '../../foodsList/shopFoods_new.vue'
  import {insert_upload_img} from '../../../api/public'
  export default {
    data() {
      return {
        ent_id: this.$route.query.ent_id,
        shop_id: this.$route.query.shop_id,
        submitForm: {},
        uploadMultipleDia: false,
        uploadIndex: '',
        uploadType: 1
      }
    },
    components: {
      uploadSingle,
      shopFoods,
      shopFoodsNew
    },
    created() {
      this.initForm()
    },
    methods: {
      // 初始化，回显数据
      async initForm() { 
        let params = {data: {ent_id: this.ent_id, shop_id: this.shop_id}}
        let res = await query_shop_intro(params), shop_info = {};
        if(res && res.code == 200) {
          // 组合图片列表数据，以便回显
          let imgList = []
          res.body.data.img_list.map(item=>{
            let obj = {}
            obj['ent_id'] = item.ent_id
            obj['shop_id'] = item.shop_id
            obj['scene'] = item.scene
            // 确定文件类型
            let fileType = 1
            let strArr = item.img_url? item.img_url.split('.'): []
            let fileAppendName = strArr[strArr.length-1]
            if(fileAppendName == 'png' || fileAppendName == 'jpg' || fileAppendName == 'gif' || fileAppendName == 'jpeg') fileType = 1
            else if(fileAppendName == 'mp4' || fileAppendName == 'wmv' || fileAppendName == 'webm' || fileAppendName == 'ogg' || fileAppendName == 'ogv')  fileType = 2

            obj['uploadType'] = fileType
            obj['img_url'] = {url: item.img_url}
            imgList.push(obj)
          })
          
          console.log('imgList', imgList)
          // 回显数据内容
          this.submitForm = {
            ent_id: this.ent_id,
            shop_id: this.shop_id,
            list: imgList,
            flag_show_review: res.body.data.flag_show_review,
            special_prod: res.body.data.special_prod
          }
        } else if(res && res.code == 700) {
          // 初始化数据内容
          this.submitForm = {
            ent_id: this.ent_id,
            shop_id: this.shop_id,
            list: [{scene: '', img_url: {}, uploadType: 1}],
            flag_show_review: '1',
            special_prod: ''
          }
        }
      },
      // 提交数据
      async onSubmit() {
        let params = {
          data: {
            ent_id: this.submitForm.ent_id,
            shop_id: this.submitForm.shop_id,
            flag_show_review: this.submitForm.flag_show_review,
            special_prod: this.submitForm.special_prod,
            list: this.getSceneList()
          }
        }
        
        await insert_shop_intro(params).then(res=>{
          if(res && res.code==200) this.$message({message: '恭喜，您已成功提交！', type: 'success'})
          else this.$message({message: res.msg, type: 'error'})
        }).catch(err=>{
          this.$message({message: err, type: 'error'})
        })
      },
      // 获取特色菜被选中的数组
      getSelectedFoods(itemArr, arr) {
        this.submitForm.special_prod = arr.join(',')
      },
      // 处理场景数据，符合上传标准
      getSceneList() {
        let sceneList = []
        this.submitForm.list.map(item=>{
          let obj = {}
          obj['scene'] = item.scene
          obj['img_url'] = item.img_url.url
          sceneList.push(obj)
        })
        return sceneList
      },
      
      // dataURLtoFile(dataurl, filename) {
      //   let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1]
      //   let bstr = atob(arr[1])
      //   console.log('bstr', bstr)
      //   return bstr
      // },
      async uploadFileFn(item, index, image) {
        await insert_upload_img({data:{
          img_data: image.base64,
          img_type: '.'+(image.name.split('.'))[1],
          img_name: image.name
        }}).then(res=>{
          console.log('item', item)
          let uploadType = ''
          let afterPre = res.body.data.img_url.split('.')[res.body.data.img_url.split('.').length - 1]
          if(afterPre == 'jpg' || afterPre == 'jpeg' || afterPre == 'png' || afterPre == 'gif') uploadType = '1'
          if(afterPre == 'mp4' || afterPre == 'ogg' || afterPre == 'avi' || afterPre == 'wmv' || afterPre == 'ogv') uploadType = '2'

          this.$set(this.submitForm.list[index], 'uploadType', uploadType)
          console.log('this.filesList',this.submitForm.list)
          this.$set(this.submitForm.list[index].img_url, 'url', res.body.data.img_url)
          
        }).catch(err=>{
          console.log('err', err)
        })
      },
      //实现图片64转化
      earlGetImgBasea(e, item, index) {
        // var event = event || window.event
        var file = e.target.files[0]
        if(file.size > 1024 * 1024) {
          this.$message({message: '文件不可超过1M', type: 'error'})
          return
        }
        console.log('item', item)
        var reader = new FileReader()
        let __this = this
        const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        // 转base64
        const arr = new Array()
        reader.onload = function (e) {
          let obj = {
            url: '',
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            base64: e.target.result.replace(/^data:image\/\w+;base64,/, "")
          }
          __this.uploadFileFn(item, index, obj)
        }
        reader.readAsDataURL(file)
        
      },
      delImg(item) {
        item.img_url.url = ''
        item.uploadType = ''
      },
      uploadSuccess(data, item, index) {
        let uploadType = ''
        // let afterPre = res.body.data.img_url.split('.')[res.body.data.img_url.split('.').length - 1]
        if(data.type=='image') uploadType = '1'
        if(data.type=='video') uploadType = '2'

        this.$set(this.submitForm.list[index], 'uploadType', uploadType)
        console.log('this.filesList',this.submitForm.list)
        this.$set(this.submitForm.list[index].img_url, 'url', data.url)

        // this.$set(this.submitForm.list[index], 'uploadType', uploadType)
        // console.log('this.filesList',this.submitForm.list)
        // this.$set(this.submitForm.list[index].img_url, 'url', res.body.data.img_url)
        
        // console.log('data', data)
        // item.img_url.url = data.url
        // item.uploadType = uploadType
      },
      // public
      
      // 以下为页面逻辑数据
      uploadImgBtn(index) {
        this.uploadIndex = index
        this.uploadType =  this.submitForm.list[index].uploadType
        this.uploadMultipleDia = true
      },
      afterUploadImg(arr) {
        this.$set(this.submitForm.list[this.uploadIndex], 'img_url', arr[0])
      },
      deleteImgBtn(imgItem, type) {
        this.$set(this.submitForm.list[type], 'img_url', {})
      },
      deleteScene(item, index) {
        if(this.submitForm.list.length <= 1) {
          this.$message({message: '提示：已经是最后一条，您可以删除图片重新编辑。', type: 'warning'})
          return
        }
        this.submitForm.list.splice(index, 1)
      },
      addScene() {
        this.submitForm.list.push({scene: '', img_url: {}, uploadType:1})
      },
    }
  }
</script>

<style scoped lang="scss">
.business-desc-box{
  .business-desc-box{ background: #fff; 
    .el-icon-delete{ color: #444; }
 }
 /deep/.EditorialEnterprise_abc { width: 100%; padding: 20px; color: #f6f6f3; background: #fefefe; border-radius: 10px;
    .EditorialEnterprise-title { padding: 23px 0 0 20px; font-size: 18px; font-family: Source Han Sans CN; font-weight: 400; display: flex; justify-content: left; align-items: center; font-size: 20px; font-family: Source Han Sans CN; font-weight: 400; color: #333333; margin-bottom: 12px;
      span { display: inline-block; width: 6px; height: 34px; border-radius: 2px; background: linear-gradient(180deg, #fc4353, #ff5e41); border-radius: 3px; margin-right: 11px; }
    }
    .Button-box { margin: 0!important; margin-bottom: 30px!important;
        button { width: 100px; height: 30px!important; line-height: 0; outline: none; border: none; display: inline-block; background: linear-gradient(200deg, #fc4353, #ff5e41); border-radius: 10px; font-size: 16px!important; font-family: Source Han Sans CN; font-weight: 400; color: #fff!important;
          span{color: #fff!important;}
        }
      }
  }
}
 
  // earl 2021-09-06
  .img_list_upload{ display: flex; flex-wrap: wrap; position: relative;
  li{ width: 148px; height: 88px; border: solid #e1e1e1 1px; box-sizing: border-box; position: relative; margin-right: 15px; margin-bottom: 15px; border-radius: 6px;
    img{ max-width: 100%; max-height: 100%;  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    video{ max-width: 100%; max-height: 100%;  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    .opt_thum{ display: none; position: absolute; width: 100%; height: 100%; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.3); text-align: center; border-radius: 6px;
      i{ color: #fff; font-size: 20px; cursor: pointer; display: inline-block; margin-top: 43%; }
    }
  }
  li.video_style{ width: auto;
    video{ width: auto; height: 148px;  }
  }
  li:hover{
    .opt_thum{ display: block; }
  }
  .edit_upload_file{ padding: 10px; color: #fc4353; font-size: 18px; }
  .edit_upload_img{ cursor: pointer; display: flex; justify-content: center; align-items: center; color: rgba(0, 0, 0, 0.6); background: #f1f1f1;}
  .edit_upload_img_input{ position: absolute; opacity: 0; background: #333; width: 148px; height: 88px; left: 0; top: 0; }
}
.tab_upload{
  span{ display: inline-block; padding: 3px 10px; line-height: 20px; font-size: 13px; border: solid #FC4353 1px; background: #fff; color: #666; margin: 10px 10px 10px 0; }
  span.active{ background: #FC4353; color: #fff; }
}
.file_url_show{
  img{ display: block; height: 108px; }
  img,video{ width: 112px;  }
  
}
.del_btn{ color: #fff; cursor: pointer; width: 100%; position: absolute; left: 0; bottom: 0; background: rgba(0,0,0,0.3); color: #fff; text-align: center; height: 20px; line-height: 20px; padding: 0; }
</style>