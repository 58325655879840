<!-- // earl 2021-09-06 前任代码，本人修改了文件名 -->
<template>
  <div class="EditorialEnterprise-box">
    <p class="EditorialEnterprise-p"><i class="el-icon-location-outline"></i><span
        @click="$router.push('/InfoMsettings')">基本信息</span> / 编辑企业门店信息</p>
    <div class="EditorialEnterprise">
      <div class="EditorialEnterprise-title">
        <span></span>
        编辑企业门店信息
      </div>
      <div class="EditorialEnterprise-Information">
        <div class="Information-file">
          <span class="StoreHead">门店头像：</span>
          <p class="touxiang" v-if="imgBase64.length===0" @click="dianji_fangda">{{mingzi}}</p>
          <img @click="dianji_fangda" v-if="imgBase64.length!==0" :src="imgBase64[0]" alt>

          <p class="shangchuan">
            <input type="file" @change="getImgBasea()">
            上传头像
          </p>
        </div>
        <el-form ref="form" :model="form" label-width="110px">
          <el-form-item label="门店名称：">
            <el-input v-model="form.shop_name" class="StoreName" placeholder="例：XXX烧烤" style="width:450px"></el-input>
          </el-form-item>
          <el-form-item label="品牌介绍：">
            <div style="color: #777;">上传图片最佳尺寸 384*216，文件不可超过1M</div>
            <div class="detail_image_video">
              <!-- 上传图片 S -->
              <div v-for="(item, index) in filesList" :key="index">
                <ul class="img_list_upload">
                  <!-- 图片 -->
                  <template v-if="item.file_url && item.file_url.url">
                    <li :class="{'video_bg': item.uploadType == '2'}">
                      <img :src="item.file_url.url" v-if="item.uploadType == '1'" />
                      <video :src="item.file_url.url" v-if="item.uploadType == '2'" controls="controls">您的浏览器不支持video标签，请更换或升级浏览器。</video>
                      <span class="opt_thum"><i class="el-icon-delete" @click="deleteImgBtn(item, index)"></i></span>
                    </li>
                  </template>
                  <li v-if="!item.file_url || !item.file_url.url" class="edit_upload_img">点击上传</li>
                  <input v-if="!item.file_url || !item.file_url.url" class="edit_upload_img_input" type="file" @change="earlGetImgBasea($event, item, index)">
                </ul>
              </div>
              <!-- 上传图片 E -->
            </div>
            <el-input type="textarea" :rows="4" maxlength="500" v-model="form.intro" style="width:630px;"
              placeholder="选填 不超过500字" show-word-limit></el-input>
          </el-form-item>
          <div class="UserInfo">
            <el-form-item label="联系人：">
              <el-input v-model="form.contact" style="width:450px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input v-model="form.mobile" style="width:450px"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="门店地址：">
            <div class="add">
              <p>
                <el-tooltip class="item" effect="dark" content="请在地图中选择门店地址" placement="top">
                         <v-distpicker class="nmendian" id="tipinput" disabled :province="form.province" :city="form.city"
                  :area="form.district" @selected="onSelected">
                </v-distpicker>
                </el-tooltip>
        

              </p>
              <span style="margin-left:100px">门店详细地址:</span>
              <el-input style="width:300px;margin-left:20px;" v-model="form.addr" placeholder="请输入内容"></el-input>

            </div>

            <myamap v-model="position" :searchText="searchText" style="width:800px;height:600px;"></myamap>

          </el-form-item>
          <div class="StoreType">
            <el-form-item label="门店类型：" style="margin-right: 100px;">
              <el-select v-model="form.type_shop_arr" @change="shopTypeChange($event)" multiple placeholder="请选择餐饮类型">
                <el-option v-for="item in shop_type_list" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
              <!-- <el-cascader v-model="form.type_shop" :options="options"
                :props="{ label: 'type_name',value:'type_id',children:'list' }"></el-cascader> -->
            </el-form-item>
            <el-form-item label="门店面积：">
              <el-input v-model="form.area" type="number" @change="handlerChange" style="width:140px;"></el-input> <span
                class="square">平方米</span>
            </el-form-item>
          </div>
          <el-form-item label="营业时间：" class="yinyeshijian">
            <!-- 随时营业 -->
            <p class="ying">

              <span>24小时营业</span>

              <el-radio-group style="margin-left:20px;" v-model="form.flag_buz_24h">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </p>
            <!-- 早午晚市选择时间 -->
            <div class="huanhang_xxxx ">
              <div>
                <el-checkbox v-model="form.flag_buz_morning" :disabled="form.flag_buz_24h=='1'">早市</el-checkbox>
                <el-time-picker v-model="form.times_morning_start" style="width:120px" value-format='HH:mm'
                  format='HH:mm' :disabled="!form.flag_buz_morning" placeholder="开始时间">
                </el-time-picker> 至
                <el-time-picker v-model="form.times_morning_end" value-format='HH:mm' format='HH:mm' style="width:120px"
                  :disabled="!form.flag_buz_morning" placeholder="结束时间" :picker-options="{
                        selectableRange: `${ form.times_morning_start+':00'}-23:59:59`}">
                </el-time-picker>
              </div>
              <div>
                <el-checkbox v-model="form.flag_buz_noon" :disabled="form.flag_buz_24h=='1'">午市</el-checkbox>
                <el-time-picker v-model="form.times_noon_start" style="width:120px" value-format='HH:mm' format='HH:mm'
                  :picker-options="{selectableRange: `${form.times_morning_start?form.times_morning_start+':00':'00:00:00' }-23:59:59`}"
                  :disabled="!form.flag_buz_noon" placeholder="开始时间">
                </el-time-picker> 至
                <el-time-picker v-model="form.times_noon_end" value-format='HH:mm' style="width:120px" format='HH:mm'
                  :disabled="!form.flag_buz_noon"
                  :picker-options="{selectableRange: `${form.times_noon_start+':00'}-23:59:59`}" placeholder="结束时间">
                </el-time-picker>
              </div>
              <div>
                <el-checkbox v-model="form.flag_buz_night" :disabled="form.flag_buz_24h=='1'">晚市</el-checkbox>
                <el-time-picker v-model="form.times_night_start" style="width:120px" value-format='HH:mm' format='HH:mm'
                  :disabled="!form.flag_buz_night" placeholder="开始时间 "
                  :picker-options="{selectableRange: `${form.flag_buz_noon?(form.times_noon_end?form.times_noon_end+':00':'00:00:00'):(form.times_morning_end?form.times_morning_end+':00':'00:00:00')          }-23:59:59`}">
                </el-time-picker> 至
                <el-time-picker v-model="form.times_night_end" value-format='HH:mm' style="width:120px" format='HH:mm'
                  :disabled="!form.flag_buz_night" placeholder="结束时间"
                  :picker-options="{selectableRange: `${form.times_night_start+':00'}-23:59:59`}">
                </el-time-picker>
              </div>
            </div>



          </el-form-item>
          <el-form-item label="是否有包厢：">
            <el-radio-group v-model="form.flag_room">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>


          </el-form-item>
          <el-form-item label="门店座位数：" v-show="form.num_table||form.num_room" class="SeatDown">
            <div class="SeatDown-box" v-show="form.num_table">
              <span>大厅{{form.num_table}}桌</span>
              <!-- <el-input-number v-model="form.num_table" controls-position="right" :min="1"></el-input-number> -->

              <!-- <span>桌</span> -->
            </div>
            <div class="SeatDown-box" v-show="form.num_room">
              <span>包厢{{form.num_room}}间</span>
              <!-- <span>包厢</span>
              <el-input-number v-model="form.num_room" :disabled="form.flag_room=='0'" controls-position="right"
                :min="1"></el-input-number>
              <span>间</span> -->
            </div>
          </el-form-item>

          <el-form-item label="接受预约范围">
            <!-- <el-radio-group v-model="form.flag_book">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group> -->
            <el-checkbox-group v-model="checkList" @change="checkList_F">
              <el-checkbox label="3">大桌</el-checkbox>
              <el-checkbox label="2">中桌</el-checkbox>
              <el-checkbox label="1">小桌</el-checkbox>
              <el-checkbox label="0" >包间</el-checkbox>
            </el-checkbox-group>

          </el-form-item>

          <el-form-item label="是否接受预约：">
            <el-radio-group v-model="form.flag_book">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>

            <span style="color:#333;margin-left:10px">可预订</span>
            <el-input-number v-model="form.days_book" :disabled='form.flag_book=="0"' controls-position="right"
              :min="1"></el-input-number>
            <span style="color:#333">天内</span>
          </el-form-item>

          <el-form-item label="正常用餐时间：" class="meal-time">
            <p class="ying">
              <el-checkbox v-model="form.flag_time_unify">统一用餐时间</el-checkbox>
              <el-input v-model="form.time_unify" style="width:150px;" type="Number" placeholder="请输入分钟"></el-input>
              <!-- <el-time-picker v-model="form.time_unify" format='HH:mm'
                :default-value="new Date(new Date().setHours(1, 0, 0, 0))"
                :picker-options="{ selectableRange: '00:00:00 - 05:00:00'}" placeholder="任意时间" style="width:130px">
              </el-time-picker> -->
            </p>

            <el-checkbox v-model="form.flag_time_morning_limit" :disabled='form.flag_time_unify'>早市</el-checkbox>
            <el-input v-model="form.time_morning_limit" :disabled='!form.flag_time_morning_limit' style="width:150px;"
              type="Number" placeholder="请输入分钟"></el-input>
            <!-- <el-time-picker :disabled='!form.Istime_zao' v-model="form.time_morning_limit" format='HH:mm'
              :default-value="new Date(new Date().setHours(1, 0, 0, 0))"
              :picker-options="{ selectableRange: '00:00:00 - 05:00:00'}" placeholder="任意时间" style="width:130px">
            </el-time-picker> -->

            <el-checkbox v-model="form.flag_time_noon_limit" :disabled='form.flag_time_unify'>午市</el-checkbox>
            <el-input v-model="form.time_noon_limit" :disabled='!form.flag_time_noon_limit' style="width:150px;"
              type="Number" placeholder="请输入分钟"></el-input>
            <!-- <el-time-picker :disabled='!form.Istime_wu' v-model="form.time_noon_limit" format='HH:mm'
              :default-value="new Date(new Date().setHours(1, 0, 0, 0))"
              :picker-options="{ selectableRange: '00:00:00 - 05:00:00'}" placeholder="任意时间" style="width:130px">
            </el-time-picker> -->

            <el-checkbox v-model="form.flag_time_night_limit" :disabled='form.flag_time_unify'>晚市</el-checkbox>
            <el-input v-model="form.time_night_limit" :disabled='!form.flag_time_night_limit' style="width:150px;"
              type="Number" placeholder="请输入分钟"></el-input>
            <!-- <el-time-picker :disabled='!form.Istime_wan' v-model="form.time_night_limit" format='HH:mm'
              :default-value="new Date(new Date().setHours(1, 0, 0, 0))"
              :picker-options="{ selectableRange: '00:00:00 - 05:00:00'}" placeholder="任意时间" style="width:130px">
            </el-time-picker> -->
          </el-form-item>

        </el-form>
        <div class="Button-box">
          <button @click="Sure" :loading="submmitLoading">确定</button>
          <button v-if="this.$route.params.type == 'edit'" @click="handledel()">删除</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {insert_upload_img} from '../../../api/public'
  import {
    insert_shop_info,
    update_shop_info,
    delete_shop_info,
    query_shop_info_list,
    // query_shop_type_tree,
    query_shop_info_detail
  } from '../../../api/store'
  import {
    mapState,
    mapActions,
    mapGetters
  } from 'vuex'
  import SimpleDateFormat from "../../fillter/filltertime.js";
  import vDistpicker from "v-distpicker"
  import "../../../utils/province_city_area";

  // import my-amap from '../../base'
  import myamap from "../../baseComponents/map";
  // import AMap from 'AMap'
  // var map

  //   var map = new AMap.Map("container", {
  //     resizeEnable: true
  // });
  // //输入提示
  // var autoOptions = {
  //     input: "tipinput"
  // };
  // var auto = new AMap.Autocomplete(autoOptions);
  // var placeSearch = new AMap.PlaceSearch({
  //     map: map
  // });  //构造地点查询类
  // AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
  // function select(e) {
  //     placeSearch.setCity(e.poi.adcode);
  //     placeSearch.search(e.poi.name);  //关键字查询查询
  // }


  export default {

    data() {
      return {
        position: [],
        searchText: '辽阳',
        value_address: '',
        options_address: window.province_city_area,
        defaultProps: {
          multiple: true,
          children: 'children',
          label: 'label',
          value: 'value',
          emitPath: false
        },

        form: {
          shop_name: '', //门店名称
          logo: '', //门店头像
          logo_type: '', //头像后缀
          intro: '', //门店简介
          contact: '', //联系人
          mobile: '', //联系电话
          province: '', //门店地址：省
          city: '', //门店地址：市
          district: '', //门店地址：区
          addr: '', //门店地址：详细地址
          type_shop: '烧烤', //门店类型
          type_shop_arr: [],
          area: '', //门店面积:x平方米
          days_book: '1',
          flag_buz_24h: '1', //营业时间：24小时营业（0和1）
          flag_buz_morning: '', //营业时间：早市（0和1）
          times_morning_start: '', //早市开始时间（格式：16:20）
          times_morning_end: '', //早市结束时间（格式：16:20）
          flag_buz_noon: '', //营业时间：午市（0和1）
          times_noon_start: '', //午市开始时间（格式：16:20）
          times_noon_end: '', //午市结束时间（格式：16:20）
          flag_buz_night: '', //营业时间：晚市（0和1）
          times_night_start: '', //晚市开始时间（格式：16:20）
          times_night_end: '', //晚市结束时间（格式：16:20）
          flag_room: '1', //是否有包厢：1是，0否
          num_table: '', //门店座位数：大厅x桌
          num_room: '', //门店座位数：包厢x间
          // book_scope:[], // 预约范围限制
          flag_book: '1', //是否接受预约：1是，0否
          flag_time_unify: true,
          time_unify: null, //正常用餐时间：统一的时间
          Istime_wan: false,
          Istime_zao: false,
          Istime_wu: false,
          time_morning_limit: null, //正常用餐时间：早市时间
          time_noon_limit: null, //正常用餐时间：午市时间
          time_night_limit: null, //正常用餐时间：晚市时间
          flag_time_morning_limit: false,
          flag_time_noon_limit: false,
          flag_time_night_limit: false,
        },
        Normaltime1: '',
        day: '',
        buszaosta: 0,
        buswusta: 0,
        buswansta: 0,
        Norstatus: false,
        busstatus: false,
        mstatus: true,
        nstatus: false,
        estatus: false,
        morning: '',
        none: '',
        evening: '',
        Normaltime: '',
        zaoValue: '',
        morningstarttime: '',
        morningendtime: '',
        nonestarttime: '',
        noneendtime: '',
        eveningendtime: '',
        eveningstarttime: '',
        wuValue: '',
        wangValue: '',
        options: [],
        imgBase64: [],
        imgtype: '',
        imgurl: '',
        value: '烧烤',
        radio: '1',
        Normaldiningstate: 0,
        busstate: 0,
        balconyRadio: '1',
        subscribeRadio: '1',
        num1: 1,
        num2: 1,
        mingzi: '无',
        BusinessList: [],
        checkList: [],
        storelist: [],
        // earl
        uploadMultipleDia: false,
        uploadType: 1,
        filesList: [{file_url: {}, uploadType: 1},{file_url: {}, uploadType: 1},{file_url: {}, uploadType: 1}],
        uploadIndex: '',
        submmitLoading: false
      }
    },
    computed: {
      ...mapGetters(['shop_type_list'])
    },
    created() {
      this.editInitInfo()
      console.log('shop_type_list', this.shop_type_list)
    },
    mounted() {
      // setTimeout(() => {
      //   this.searchText = this.form.province + this.form.city + this.form.district
      // }, 1000);
      // this.init()
    },
    components: {
      vDistpicker,
      myamap,
      // uploadImage,
      // uploadVideo
      // AMap
    },
    methods: {
      async editInitInfo() {
        sessionStorage.removeItem('pc_user_pic');
        // this.options_address = window.province_city_area
        // flag_time_unify
        console.log(this.$route.query.pageType)
        if (this.$route.query.pageType == 'edit') {
          
          let ent_id = this.$route.query.ent_id
          let shop_id = this.$route.query.shop_id
          let res = await query_shop_info_detail({data: {ent_id, shop_id}})
          let form = res.body.data
          // form.book_scope = '1'
          this.form = form
          console.log('this.form', this.form)
          this.checkList = form.book_scope.split(',') // 预约的桌台设置

          // earl  09-16 S
          // let imagesList = []
          this.filesList = []
          form.img_list.map(item=>{
            let afterPre = item.img_url.split('.')[item.img_url.split('.').length - 1]
            let uploadType = '1'
            if(afterPre == 'jpg' || afterPre == 'jpeg' || afterPre == 'png' || afterPre == 'gif') uploadType = '1'
            if(afterPre == 'mp4' || afterPre == 'ogg' || afterPre == 'avi' || afterPre == 'wmv' || afterPre == 'ogv') uploadType = '2'
            this.filesList.push({uploadType: uploadType, file_url: {url: item.img_url}})
          })
          // this.filesList = imagesList
          this.form.type_shop_arr = this.form.type_shop.split(',')
          // earl  09-16 E


          if (this.form.flag_time_unify == '1') {
            //
            this.form.flag_time_unify = true
          } else {
            this.form.flag_time_unify = false
          }



          if (this.form.flag_buz_morning == '1') {
            //
            this.form.flag_buz_morning = true
          } else {
            this.form.flag_buz_morning = false
          }

          if (this.form.flag_buz_noon == '1') {
            this.form.flag_buz_noon = true
          } else {
            this.form.flag_buz_noon = false
          }

          if (this.form.flag_buz_night == '1') {
            this.form.flag_buz_night = true
          } else {
            this.form.flag_buz_night = false
          }



          if (this.form.lon) {
            let a = [Number(this.form.lon), Number(this.form.lat)]
            setTimeout(() => {
              this.position = a
            }, 100);

          } else {
            setTimeout(() => {
              this.searchText = this.form.province + this.form.city + this.form.district

            }, 100);
          }









          if (this.form.flag_time_morning_limit) {
            if (this.form.flag_time_morning_limit == '1') {
              this.$set(this.form, 'flag_time_morning_limit', true)
              // this.form.flag_time_morning_limit = true

            } else {
              this.$set(this.form, 'flag_time_morning_limit', false)
              // this.form.flag_time_morning_limit = false

            }
          } else {
            this.$set(this.form, 'flag_time_morning_limit', false)
            // this.form.flag_time_morning_limit = false
          }











          if (this.form.flag_time_noon_limit) {
            if (this.form.flag_time_noon_limit == '1') {
              this.$set(this.form, 'flag_time_noon_limit', true)
              // this.form.flag_time_noon_limit = true
            } else {
              this.$set(this.form, 'flag_time_noon_limit', false)
              // this.form.flag_time_noon_limit = false
            }
          } else {
            this.$set(this.form, 'flag_time_noon_limit', false)
            // this.form.flag_time_noon_limit = false
          }







          if (this.form.flag_time_night_limit) {
            if (this.form.flag_time_night_limit == '1') {
              this.$set(this.form, 'flag_time_night_limit', true)
              // this.form.flag_time_night_limit = true
            } else {
              this.$set(this.form, 'flag_time_night_limit', false)
              // this.form.flag_time_night_limit = false
            }
          } else {
            this.$set(this.form, 'flag_time_night_limit', false)
            // this.form.flag_time_night_limit = false

          }
          this.imgBase64[0] = this.form.logo
          console.log(this.form)
        }
        
        query_shop_info_list({data: {ent_id: this.$store.state.ent_id}}).then(res => {
          if (res.code == 200) {
            this.storelist = res.body.data
            console.log('this.storelist', this.storelist)
          } else {
            this.$message({
              message: '获取门店信息失败，请先登陆企业账号',
              type: 'error'
            })
          }
        })



        // query_shop_type_tree({

        // }).then(res => {
        //   let arr = res.body.data
        //   for (const i in arr) {
        //     if (arr[i].list instanceof Array) {
        //       if (arr[i].list.length == 0) {
        //         delete arr[i].list
        //       } else {
        //         for (const k in arr[i].list) {
        //           if (arr[i].list[k].list.length == 0) {
        //             delete arr[i].list[k].list
        //           }
        //         }
        //       }
        //     }
        //   }
        //   // this.$set(this.options,arr)
        //   console.log(arr)
        //   this.options = arr
        // })
      },
      uploadImgBtn(index) {
        this.uploadIndex = index
        this.uploadType =  this.filesList[index].uploadType
        this.uploadMultipleDia = true
      },
      // afterUploadImg(arr) {
      //   console.log('arr', arr)
      //   this.$set(this.filesList[this.uploadIndex], 'file_url', arr[0])
      // },

      deleteImgBtn(imgItem, index) {
        this.$set(this.filesList[index], 'file_url', {})
      },

      checkList_F(val){
        console.log(this.checkList);//book_scope
        console.log(this.form.book_scope);//
      },
      init() {
        // map = new AMap.Map('container', {
        //   center: [116.397428, 39.90923],
        //   resizeEnable: true,
        //   zoom: 10
        // })

        // var autoOptions = {
        //   input: "tipinput"
        // };

        // var auto = new AMap.Autocomplete(autoOptions);
        // var placeSearch = new AMap.PlaceSearch({
        //   map: map
        // }); //构造地点查询类
        // AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发


        // AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.Autocomplete'], function () {
        //   // AMap.plugin('AMap.Autocomplete', function(){
        //   // 实例化Autocomplete
        //   var autoOptions = {
        //     //city 限定城市，默认全国
        //     city: '全国'
        //   }
        //   var autoComplete = new AMap.Autocomplete(autoOptions);
        //   autoComplete.search('全国', function (status, result) {
        //     console.log(result)
        //     // 搜索成功时，result即是对应的匹配数据
        //     // })
        //   })
        //   map.addControl(new AMap.ToolBar())
        //   map.addControl(new AMap.Scale())
        // })
      },
      
      handlerChange(val) {
        this.form.area = Number(val).toFixed(2)
      },
      onSelected(val) {
        this.form.province = val.province.value
        if (val.city.value == '') {
          this.form.city = val.province.value
        } else {
          this.form.city = val.city.value
        }
        this.form.district = val.area.value
      },
      //判断早午晚  选中状态

      //实现图片64转化
      getImgBasea() {
        var event = event || window.event
        var file = event.target.files[0]
        var reader = new FileReader()
        const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        // 转base64
        const arr = []
        var _this = this
        reader.onload = function (e) {
          arr.push(e.target.result)
          _this.xin = e.target.result.substring(
            e.target.result.indexOf(',') + 1,
            e.target.result.length
          )
          const obj = {
            xin: _this.xin,
            lei: lei
          }
          sessionStorage.setItem('pc_user_pic', _this.xin)
        }
        reader.readAsDataURL(file)
        _this.imgBase64 = arr
        this.imgtype = lei
      },
      //点击头像放大
      dianji_fangda() {
        this.fangda = true
      },

      handledel() {
        delete_shop_info({
          data: {
            ent_id: this.$store.state.ent_id,
            shop_id: this.$route.params.data.shop_id,
            user_id: sessionStorage.getItem('user_id'),
            shop_name: this.$route.params.data.shop_name
          }
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.$router.push('/InfoMsettings')
          } else {
            this.$message({
              message: '删除失败',
              type: 'error'
            })
          }
        })
      },
      //确认
      ...mapActions(['get_staff_list']),
      timestampToTime(timestamp) {
        var date = new Date(timestamp * 1); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear();
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1);
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1);
        var m = date.getMinutes() + ':';
        var s = date.getSeconds(); + ':';
        var c = date.getMilliseconds()
        return `${D} : ${h}`;
      },
      Sure() {
        this.submmitLoading = true
        this.imgurl = sessionStorage.getItem('pc_user_pic')
        //如果是增加门店
        if (this.$route.params.type == 'add') {
          for (const i in this.storelist) {
            if (this.storelist[i].shop_name == this.form.shop_name) {
              this.$message.error('店名重复')
              return
            }
          }
          if (!this.imgurl) {
            this.$message.error('请上传门店头像')
            return
          }
          if (!this.form.shop_name) {
            this.$message.error('请输入门店名称')
            return
          }
          if (!this.form.intro) {
            this.$message.error('请输入门店描述')
            return
          }
          if (!this.form.contact) {
            this.$message.error('请输入联系人')
            return
          }
          if (!this.form.mobile) {
            this.$message.error('请输入联系人电话')
            return
          }
          console.log(this.form)
          if (this.imgurl) {
            this.form.ent_id = this.$store.state.userInfo.ent_info.ent_id;
            this.form.user_id = this.$store.state.user_id;
            // times_morning_end

            let imagesList = []
            this.filesList.map(item=>{
              imagesList.push({img_url: item.file_url.url ? item.file_url.url: ''})
            })
            //校验手机号码
            let param = {
              ent_id: this.$store.state.ent_id, //企业id
              user_id: this.$store.state.user_id, //用户id		02
              shop_name: this.form.shop_name, //门店名称
              logo: this.imgurl, //门店头像
              logo_type: this.imgtype, //头像后缀
              intro: this.form.intro, //门店简介
              contact: this.form.contact, //联系人
              mobile: this.form.mobile, //联系电话
              province: this.form.province, //门店地址：省
              city: this.form.city, //门店地址：市
              district: this.form.district, //门店地址：区
              addr: this.form.addr, //门店地址：详细地址
              type_shop: this.form.type_shop, //门店类型
              area: this.form.area, //门店面积:x平方米
              days_book: String(this.form.days_book),
              flag_buz_24h: String(this.form.flag_buz_24h), //营业时间：24小时营业（0和1）
              flag_buz_morning: String(Number(this.form.flag_buz_morning)), //营业时间：早市（0和1）
              times_morning_start: this.form.times_morning_start, //早市开始时间（格式：16:20）
              times_morning_end: this.form.times_morning_end, //早市结束时间（格式：16:20）
              flag_buz_noon: String(Number(this.form.flag_buz_noon)), //营业时间：午市（0和1）
              times_noon_start: this.form.times_noon_start, //午市开始时间（格式：16:20）
              times_noon_end: this.form.times_noon_end, //午市结束时间（格式：16:20）
              flag_buz_night: String(Number(this.form.flag_buz_night)), //营业时间：晚市（0和1）
              times_night_start: this.form.times_night_start, //晚市开始时间（格式：16:20）
              times_night_end: this.form.times_night_end, //晚市结束时间（格式：16:20）
              flag_room: this.form.flag_room, //是否有包厢：1是，0否
              num_table: String(this.form.num_table), //门店座位数：大厅x桌
              num_room: String(this.form.num_room), //门店座位数：包厢x间
              flag_book: this.form.flag_book, //是否接受预约：1是，0否
              flag_time_unify: String(Number(this.form.flag_time_unify)),
              time_unify: this.form.time_unify, //正常用餐时间：统一的时间
              book_scope: this.checkList.join(','), // 选择预约桌台

              flag_time_morning_limit: String(Number(this.form.flag_time_morning_limit)),
              flag_time_noon_limit: String(Number(this.form.flag_time_noon_limit)),
              flag_timenight_limit: String(Number(this.form.flag_time_night_limit)),


              time_morning_limit: this.form.time_morning_limit, //正常用餐时间：早市时间
              time_noon_limit: this.form.time_noon_limit, //正常用餐时间：午市时间
              time_night_limit: this.form.time_night_limit, //正常用餐时间：晚市时间
              lon: String(this.position[0]),
              lat: String(this.position[1]),
              img_list: imagesList

              // position
            }
            
            const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (myreg.test(this.form.mobile) && this.form.mobile !== '') {
              insert_shop_info({
                data: param
              }).then(res => {
                this.submmitLoading = false
                if (res.code == 200) {
                  //这俩
                  this.get_staff_list()

                  this.$message({
                    message: '添加门店成功',
                    type: 'success'
                  })
                  this.$router.push('/InfoMsettings')
                }
                if (res.code == 500) {
                  this.$message({
                    message: '添加门店失败',
                    type: 'error'
                  })
                }
                if (res.code == 600) {
                  this.$message({
                    message: '添加门店失败,名称重复',
                    type: 'error'
                  })
                }
                if (res.code =='1200'||res.code =='1100') {
                  this.$message({
                    message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                    type: 'error'
                  })
                }
                console.log(res, '新增门店信息');
                
              })

            } else {
              this.$message({
                message: '请填写手机号码或者手机号码格式错误',
                type: 'error'
              })
            }
          } else {
            this.$message({
              message: '请填写完整信息',
              type: 'error'
            })
          }
        } else {
            let imagesList = []
            this.filesList.map(item=>{
              imagesList.push({img_url: item.file_url.url ? item.file_url.url: ''})
            })
          // if (this.imgurl) {
          //校验手机号码
          const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if (myreg.test(this.form.mobile) && this.form.mobile !== '') {
            update_shop_info({
              data: {
                ent_id: this.$store.state.ent_id, //企业id
                user_id: this.$store.state.user_id, //用户id		02
                shop_name: this.form.shop_name, //门店名称
                shop_id: this.form.shop_id, //门店名称
                logo: this.imgurl, //门店头像
                logo_type: this.imgtype, //头像后缀
                intro: this.form.intro, //门店简介
                contact: this.form.contact, //联系人
                mobile: this.form.mobile, //联系电话
                province: this.form.province, //门店地址：省
                city: this.form.city, //门店地址：市
                district: this.form.district, //门店地址：区
                addr: this.form.addr, //门店地址：详细地址
                type_shop: this.form.type_shop, //门店类型
                area: this.form.area, //门店面积:x平方米
                days_book: String(this.form.days_book),
                flag_buz_24h: String(this.form.flag_buz_24h), //营业时间：24小时营业（0和1）
                flag_buz_morning: String(Number(this.form.flag_buz_morning)), //营业时间：早市（0和1）

                times_morning_start: this.form.times_morning_start, //早市开始时间（格式：16:20）
                times_morning_end: this.form.times_morning_end, //早市结束时间（格式：16:20）

                flag_buz_noon: String(Number(this.form.flag_buz_noon)), //营业时间：午市（0和1）
                times_noon_start: this.form.times_noon_start, //午市开始时间（格式：16:20）
                times_noon_end: this.form.times_noon_end, //午市结束时间（格式：16:20）
                flag_buz_night: String(Number(this.form.flag_buz_night)), //营业时间：晚市（0和1）
                times_night_start: this.form.times_night_start, //晚市开始时间（格式：16:20）
                times_night_end: this.form.times_night_end, //晚市结束时间（格式：16:20）
                flag_room: this.form.flag_room, //是否有包厢：1是，0否
                num_table: String(this.form.num_table), //门店座位数：大厅x桌
                num_room: String(this.form.num_room), //门店座位数：包厢x间
                flag_book: this.form.flag_book, //是否接受预约：1是，0否
                flag_time_unify: String(Number(this.form.flag_time_unify)),
                time_unify: this.form.time_unify, //正常用餐时间：统一的时间
                time_morning_limit: this.form.time_morning_limit, //正常用餐时间：早市时间
                time_noon_limit: this.form.time_noon_limit, //正常用餐时间：午市时间
                time_night_limit: this.form.time_night_limit, //正常用餐时间：晚市时间
                book_scope: this.checkList.join(','), // 选择预约桌台


                flag_time_morning_limit: String(Number(this.form.flag_time_morning_limit)),
                flag_time_noon_limit: String(Number(this.form.flag_time_noon_limit)),
                flag_time_night_limit: String(Number(this.form.flag_time_night_limit)),

                lon: String(this.position[0]),
                lat: String(this.position[1]),
                img_list: imagesList
              }
            }).then(res => {
              if (res.code == 200) {
                this.$message({
                  message: '修改门店信息成功',
                  type: 'success'
                })
                this.$router.push('/InfoMsettings')
              }
              if (res.code == 500) {
                this.$message({
                  message: '修改门店失败',
                  type: 'error'
                })
              }
              if (res.code =='1200'||res.code =='1100') {
                this.$message({
                  message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                  type: 'error'
                })
              }
              console.log(res, '修改门店信息');
            })

          } else {
            this.$message({
              message: '请填写手机号码或者手机号码格式错误',
              type: 'error'
            })
          }
          this.submmitLoading = false
        }
        
      },
      async uploadFileFn(item, index, image) {
        console.log('image', image)
        await insert_upload_img({data:{
          img_data: image.base64,
          img_type: '.'+(image.name.split('.'))[1],
          img_name: image.name
        }}).then(res=>{
          console.log('item', item)
          let uploadType = ''
          let afterPre = res.body.data.img_url.split('.')[res.body.data.img_url.split('.').length - 1]
          console.log('afterPre', afterPre)
          if(afterPre == 'jpg' || afterPre == 'jpeg' || afterPre == 'png' || afterPre == 'gif') uploadType = '1'
          if(afterPre == 'mp4' || afterPre == 'ogg' || afterPre == 'avi' || afterPre == 'wmv' || afterPre == 'ogv') uploadType = '2'

          this.$set(this.filesList[index], 'uploadType', uploadType)
          console.log('this.filesList',this.filesList)
          this.$set(this.filesList[index].file_url, 'url', res.body.data.img_url)
          
        }).catch(err=>{
          console.log('err', err)
        })
      },
      shopTypeChange(v) {
        this.$set(this.form, 'type_shop_arr', v)
        this.$set(this.form, 'type_shop', v.join(','))
        this.$forceUpdate()
      },
      //实现图片64转化
      earlGetImgBasea(e, item, index) {
        // console.log('eeeeee', e)
        // var event = event || window.event
        var file = e.target.files[0]
        // console.log('item', file, item)
        if(file.size > 1024 * 1024) {
          this.$message({message: '文件不可超过1M', type: 'error'})
          return
        }
        var reader = new FileReader()
        let __this = this
        const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        // 转base64
        const arr = new Array()
        reader.onload = function (e) {
          let obj = {
            url: '',
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            base64: e.target.result.replace(/^data:image\/\w+;base64,/, "")
          }
          __this.uploadFileFn(item, index, obj)
        }
        reader.readAsDataURL(file)
        
      },
    }
  }
</script>

<style scoed lang="scss">
  .ying {
    // display: flex;
    // align-items: center;

    img {
      // padding-right: 10px;
    }

  }

  .nmendian {
    select {
      padding: 10px 2px;
      font-size: 14px;

      option {
        padding: 20px 20px;
        min-height: 40px;
        font-size: 14px;
      }
    }
  }

  select:not(:-internal-list-box) {
    overflow: visible !important;
  }

  .yinyeshijian {
    color: #333;

    .el-form-item__content {
      display: flex;
      vertical-align: middle;
      // align-items: center;
    }

    .el-checkbox {
      // margin: 0 14px;
      margin-left: 20px;
    }

  }

  .xuanimg {
    width: 20px;
    height: 20px;
  }

  .Information-file {
    .touxiang {
      height: 95px;
      display: block;
      width: 95px;
      border-radius: 50%;
      cursor: pointer;
      line-height: 95px;
      background: #1890ff;
      color: #fff;
      font-size: 40px;
      text-align: center;
      font-family: NSimSun;
    }

    img {
      height: 95px;
      display: block;
      width: 95px;
      border-radius: 50%;
      // margin: 0 auto;
      cursor: pointer;
    }

    .shangchuan {
      position: relative;
      text-align: center;
      color: #1890ff;
      font-size: 14px;
      cursor: pointer;
      margin-left: 20px;

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        padding: 0 !important;
        cursor: pointer;
      }
    }
  }

  .add {
    display: flex;

    p {
      display: block;
    }
  }



  .el-main {

    background: #f2f6fc;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
  }

  .EditorialEnterprise-p {
    font-size: 18px;
    color: #999999;
    // display: flex;
    margin: 26px 0 30px 0;

    // line-height: 26px;
    span {
      // margin: 4px;
    }

    i {
      font-size: 26px;

      // display: block;
      // margin-right: 11px;
    }
  }

  .EditorialEnterprise {
    width: 100%;
    // min-height: 1000px;
    // padding-bottom: 300px;
    color: #f6f6f3;
    background: #fefefe;
    border-radius: 10px;

    .EditorialEnterprise-title {
      padding: 23px 0 0 20px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 12px;

      span {
        display: inline-block;
        width: 6px;
        height: 34px;
        border-radius: 2px;
        background: linear-gradient(180deg, #fc4353, #ff5e41);
        border-radius: 3px;
        margin-right: 11px;
      }
    }

    .EditorialEnterprise-Information {
      margin-top: 50px;
      padding-left: 53px;

      .Information-file {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 20px;
        margin-bottom: 24px;

        .StoreHead {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          padding-left: 10px;
        }

        .FileHead {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7090e2;
          padding-left: 13px;
        }

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          margin-left: 20px;
        }

        .avatar {
          width: 60px;
          height: 60px;
          display: block;
        }
      }

      .UserInfo {
        display: flex;
        flex-wrap: wrap;
      }

      .StoreType {
        display: flex;
        flex-wrap: wrap;
      }

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      label {
        color: #333;
      }

      .Time-box {
        display: inline-block;
        width: 178px;
        height: 44px;
        background: #f0f0f0;
        border-radius: 10px;
        line-height: 44px;
        text-align: center;
        margin-right: 6%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;

        img {
          vertical-align: middle;
          width: 20px;
          height: 20px;
          margin-left: 15px;
        }
      }

      .Time-box:last-child {
        margin-right: 0;
      }

      .Time-radio {
        margin-right: 6%;
      }

      .BalconyRadio {
        margin-right: 50px;
      }

      .Time-font {
        color: #333333;
      }

      .MealTime {
        display: inline-block;
        width: 122px;
        height: 44px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 44px;
        text-align: center;
        border-radius: 10px;
        color: #999999;
        background: #f0f0f0;
        margin-right: 30px;
      }

      .SeatDown {
        .SeatDown-box {
          display: inline-block;
          margin-right: 49px;

          &>span {
            &:first-of-type {
              margin-right: 8px;
            }
          }

          span {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
        }

        .SureButton {
          width: 110px;
          height: 50px;
          background: linear-gradient(200deg, #fc4353, #ff5e41);
          border-radius: 10px;
        }
      }

      .Button-box {
        width: 500px;
        height: 50px;
        margin: 150px auto;
        display: flex;
        justify-content: space-between;

        button {
          width: 100px;
          height: 50px;
          outline: none;
          border: none;
          display: inline-block;
          background: linear-gradient(200deg, #fc4353, #ff5e41);
          border-radius: 10px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  .huanhang_xxxx {
    display: flex;
    flex-flow: column nowrap;

  }

  .huanhang_xxxx ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  /deep/.distpicker-address-wrapper.select {
    font-size: 14px;
  }

  .map-container {
    width: 300px;
    height: 300px;
  }


.detail_image_video{ display: flex; margin-bottom: 15px;
  .img_list_upload{ display: flex; flex-wrap: wrap; position: relative;
    li{ width: 148px; height: 88px; border: solid #e1e1e1 1px; box-sizing: border-box; position: relative; margin-right: 45px; border-radius: 6px;
      img{ max-width: 100%; max-height: 100%;  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
      video{ max-width: 100%; max-height: 100%;  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
      .opt_thum{ display: none; position: absolute; width: 100%; height: 100%; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.3); text-align: center; border-radius: 6px;
        i{ color: #fff; font-size: 20px; cursor: pointer; display: inline-block; margin-top: 43%; }
      }
    }
    li.video_bg{ background: #000; }
    li.video_style{ width: auto;
      video{ width: auto; height: 80px;  }
    }
    li:hover{
      .opt_thum{ display: block; }
    }
    .edit_upload_img{ cursor: pointer; display: flex; justify-content: center; align-items: center; color: rgba(0, 0, 0, 0.6); background: #f1f1f1;}
    .edit_upload_img_input{ position: absolute; opacity: 0; background: #333; width: 148px; height: 88px; left: 0; top: 0; }
  }
  .tab_upload{
    span{ display: inline-block; padding: 0 5px; line-height: 20px; font-size: 12px; border: solid #fc4353 1px; background: #fff; margin: 0 10px 10px 0; }
    span.active{ background: #fc4353; color: #fff; }
  }
}
  
</style>