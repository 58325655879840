<template>
  <div>
    <el-upload class="avatar-uploader" action="string"
      :show-file-list="false"
      :http-request="httprequest"
      :before-upload="(file)=>beforeAvatarUpload(file)">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon">上传图片/视频</i>
    </el-upload>
  </div>
</template>

<script>
import { file_upload_ming } from "@/api/public";
export default {
  name: "uploadImg",
  props: {

  },
  data() {
    return {
      imageUrl: '',
    }
  },
  created() {

  },
  methods: {
    httprequest(file) {
    },
    async beforeAvatarUpload(file) {
      const isImage = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif')
      const isVideo = (file.type === 'video/ogg' || file.type === 'video/mp4' || file.type === 'video/webm' || file.type === 'video/swf')
      const isLt2M = file.size / 1024 / 1024 / 2 // 2 M

      if (!isImage && !isVideo) {
        this.$message({message: '上传文件格式jpeg/png/jpg/gif，视频格式ogg/mp4/webm/swf!', type: 'error', duration: 4000});
        return
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2M!');
        return
      }
      let formData = new FormData();
      formData.append('files', file)
      formData.append('type', isImage? 'image': isVideo? 'video': '')
      let res = await file_upload_ming(formData)
      console.log('res------', res)
      let data = res.body.data
      this.$emit('uploadSuccess', data)
      
    },
  },
  watch: {
    imgurl: function (v) {
      // this.getFileListDetail();
    },
  },
};
</script>


<style lang="scss">
.avatar-uploader{ width: 112px; height: 108px; border: 1px dashed #d9d9d9; }
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 12px;
    color: #8c939d;
    width: 112px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 112px;
    height: 108px;
    display: block;
  }
</style>
