<!-- // earl 2021-09-06 -->
<template>
  <div class="business_info">
    <div class="toggle">
      <ul>
        <li @click="active=1" :class="active==1 ? 'current_ele' : ''"><p>编辑门店信息</p></li>
        <li @click="active=2" :class="active==2 ? 'current_ele' : ''"><p>商家介绍</p></li>
      </ul>
    </div>
    <update-info v-if="active===1"></update-info>
    <business-desc v-if="active===2"></business-desc>
  </div>
</template>
<script>
import updateInfo from './updateInfo.vue'
import businessDesc from './business_desc.vue'
export default{
  name: 'EditorialEnterprise',
  data() {
    return {
      active: 1
    }
  },
  components: {
    updateInfo,businessDesc
  }

}
</script>
<style lang="scss" scoped>
.toggle { font-size: 15px; color: #333; display: flex; height: 80px; display: block; flex-flow: column nowrap; align-items: center; margin-bottom: 30px; -moz-box-shadow: 0px 0px 10px #e8e8eb; -webkit-box-shadow: 0px 0px 10px #e8e8eb; box-shadow: 0px 0px 10px #e8e8eb;
  & > ul { width: 100%; height: 80px; background: #ffffff; border-radius: 10px; flex: 1; display: flex; justify-content: space-around;
    li { color: #333333; line-height: 80px;
      &:hover { cursor: pointer;}
    }
  }
  .current_ele { color: #fc4353; position: relative; text-shadow: 3px 6px 3px rgba(252, 80, 95, 0.4);
    &:before { content: ''; width: 100%; height: 5px; border-radius: 3px; background: #fc4353; position: absolute; display: block; left: 0; bottom: 0;}
  }
}
</style>
